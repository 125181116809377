import {
  section,
  container,
  main,
  mainRaised,
  grayColor,
  breadcrumbs,
  coloredShadow,
  cardTitle,
  description,
  gridItem,
  gridContainer,
} from '../materialKitBase';

import { createStyles } from '@material-ui/core/styles';

export const qrStyle = createStyles({
  gridContainer,
  gridItem,
  description,
  cardTitle,
  coloredShadow,
  breadcrumbs,
  main,
  mainRaised,
  container: {
    ...container,
  },
  section: {
    ...section,
    padding: '0px 0px',
  },
  icon: {
    marginRight: '5px',
    width: 20,
    height: 20,
  },
  link: {
    display: 'flex',
  },
  parallax: {
    height: '100vh',
    overflow: 'hidden',
  },
  qrPage: {
    minHeight: '100vh',
    backgroundColor: grayColor[2],
  },
  quote: {
    padding: '1px 5px',
    margin: '0 0 1px',
    fontSize: '1.25rem',
    borderLeft: '5px solid ' + grayColor[2],
  },
  quoteText: {
    margin: '0 0 10px',
    fontStyle: 'italic',
  },
  containerRaw: {
    '&:nth-of-type(n+2)': {
      marginTop: '20px',
    },
  },
  shapeImage: {
    height: '670px',
    float: 'right',

    clipPath: 'circle(70% at right 60%)',
    shapeOutside: 'circle(73% at right 60%)',

    borderRight: '5px dashed black',
    boxShadow: '20px 0px 10px 10px rgba(0, 255, 0, .2)',
  },

  shapeImage2: {
    float: 'left',
    height: '670px',

    // minHeight: '565px',

    clipPath: 'circle(70% at left 60%)',
    shapeOutside: 'circle(73% at left 60%)',

    borderLeft: '5px dashed black',
    boxShadow: '-20px 0px 10px 10px rgba(0, 255, 0, .2)',
  },
});
