import React from 'react';
import { qrStyle } from '../assets/jss/pages/qrStyle';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultHeader } from '../components/Header/Header';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { graphql, PageRendererProps } from 'gatsby';
import { CategoryNode } from '../interfaces/contentful';
import { createCategoryTree } from '../helpers/treeHelper';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(qrStyle);

const QRPage: React.FC<ThankYouPageProps> = ({ data }) => {
  const { categories } = data;

  const categoryTree = createCategoryTree(categories);

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.qrPage}>
      <Helmet>
        <title>QR</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />

      <div style={{ display: 'flex', marginTop: '100px', justifyContent: 'center' }}>
        <StaticImage
          // formats={['auto']}
          style={{ maxWidth: '450px', margin: '40px 10px' }}
          src="../assets/img/qrNew.svg"
          alt="QR code"
        />
      </div>
    </div>
  );
};
export default QRPage;

export const thankYouQuery = graphql`
  query {
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
          }
        }
      }
    }
  }
`;

interface ThankYouPageProps extends PageRendererProps {
  data: {
    categories: {
      edges: { node: CategoryNode }[];
    };
  };
}
